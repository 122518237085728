import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Barge } from '@/app/core/models/barges.model';
import { map } from 'rxjs';
import { BoatDiagram, BoatsPosition } from '@/app/core/models/boats.model';
import {
  Boat,
  BoatDetails,
  Customer,
  DashboardData,
  DashboardParams,
  LockDelay,
  River,
  RiverCondition,
  RiverStage,
} from '../models/dashboard.model';

export type ParsedBoatDiagram = (BoatDiagram | null)[][];

@Injectable({ providedIn: 'root' })
export class StaticDataService {
  http = inject(HttpClient);

  getDashboardData(params: DashboardParams) {
    return this.http.get<DashboardData>('/api/TowlineDashboard', { params });
  }

  getBoats() {
    return this.http.get<Boat[]>('/api/TowlineBoats');
  }
  getBoatsPositions() {
    return this.http.get<BoatsPosition[]>(
      '/api/TowlineBoatPositionsGroupedByRiver',
    );
  }
  getBoatsDiagram(boatId: string, username: string) {
    return this.http.get<BoatDiagram[]>('/api/TowlineBoatPositions', {
      params: {
        boatId,
        username,
      },
    });
  }
  getRivers() {
    return this.http.get<River[]>('/api/TowlineRivers');
  }
  getBarges(
    dashboardType?: string,
    username?: string,
    filterId?: string | number,
  ) {
    return this.http.get<Barge[]>('/api/TowlineData', {
      params: {
        dashboardType: dashboardType ? `${dashboardType}BARGES` : '',
        username: username ?? '',
        filterId: filterId ?? '',
      },
    });
  }
  getBarge(username: string, params: object = {}) {
    return this.http
      .get<Barge[]>('/api/TowlineData', {
        params: {
          username,
          ...params,
        },
      })
      .pipe(map((barges) => barges[0]));
  }
  getWeatherConditions() {
    return this.http.get<RiverCondition[]>('/api/TowlineWeatherConditions');
  }
  getRiverStages() {
    return this.http.get<RiverStage[]>('/api/TowlineRiverStagesGroupByRiver');
  }
  getLockDelays() {
    return this.http.get<LockDelay[]>('/api/TowlineLockDelaysGroupedByRiver');
  }
  getCustomers() {
    return this.http.get<Customer[]>('/api/TowlineCustomers');
  }
  getBoat(boatId: string) {
    return this.http.get<Boat[]>(`/api/TowlineBoats/${boatId}`);
  }
  getBoatDetails(boatId: string) {
    return this.http.get<BoatDetails>(`/api/TowlineBoatDetails/${boatId}`);
  }
}
